import React, { PureComponent } from "react"

/* Constants */
import { env, utils } from "../constants"

/* Icons */
import { CaretDownOutlined } from '@ant-design/icons'

/* Components */
import { Picture } from "../components"



/* Component Rules */
class Rules extends PureComponent {

    constructor() {
        super()
        this.state = {
            index: 0,
            active: -1,
        }

        this.scroll = null
    }

    componentDidMount() {
        if (this.scroll) {
            this.scroll.addEventListener('scroll', this.handleScroll)
        }
    }

    componentWillUnmount() {
        if (this.scroll) {
            this.scroll.removeEventListener('scroll', this.handleScroll)
        }
    }


    handleScroll = () => {
        const container = this.scroll
        const scrollTop = container.scrollTop
        let visibleElementIndex = 0
        Array.from(container.children).forEach((item, index) => {
            if ((item.offsetTop - 50) < scrollTop) {
                visibleElementIndex = index
            }
        })
        this.setState({ index: visibleElementIndex })
    }

    getInform = (index) => {
        const container = this.scroll
        this.setState({ index })
        if (container && container.children.length > index) {
            const element = container.children[index].offsetTop - 40
            container.scrollTop = element
        }
    }

    render = () => {

        const { index, active } = this.state
        const { close, mobile, list } = this.props

        const hidden = active === -1 ? "hidden" : ""

        if (mobile) {
            return (
                <div className="mobile-rules">
                    <div className={`mobile-rules-inner ${hidden}`}>
                        {
                            list && Array.isArray(list) && list.map((item, index) => {
                                return (
                                    <div key={`rule-mobile-${index}`} className={`rule-item ${active === index ? "visible" : "default"}`}>
                                        {/* Draw history head */}
                                        <div onClick={() => this.setState({ active: active === index ? -1 : index })} className={`rule-head ${index === active ? 'rule-active-head' : ''}`}>
                                            <div className="rule-title">
                                                {item.title}
                                            </div>
                                            <CaretDownOutlined />
                                        </div>
                                        <div className={`rule-hidden-box ${index === active ? 'rule-hidden-box-active' : ''}`}>
                                            {item.content}
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="rule-bottom-space" />
                    </div>
                </div>
            )
        }

        return (
            <div className='rule-modal-content rules'>

                <div className='rule-modal-header'>
                    {utils.translate('How to play')}
                    <div onClick={() => close()} className="rule-modal-close">
                        <Picture src={`${env.mediapoint}/images/close.png`} alt="CLOSE" />
                    </div>
                </div>
                <div className="rule-modal-inform">
                    <div className="rule-modal-titles">
                        {
                            list.map((item, ind) => {
                                const style = index === ind ? 'active' : ''
                                return (
                                    <div className={style} onClick={() => this.getInform(ind)} key={`title-${item.id}`}>{item.title}</div>
                                )
                            })
                        }
                    </div>
                    <div className="rule-modal-text" ref={ref => this.scroll = ref}>
                        {
                            list.map((item, index) => {
                                return (
                                    <div key={`text-${item.id}`} className={index}>
                                        <div className="title">{item.title}</div>
                                        {item.content}
                                    </div>
                                )
                            })
                        }
                        <div className="rule-mobile-bottom" />
                    </div>
                </div>
            </div>
        )
    }

}

export default Rules