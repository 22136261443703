import React, { PureComponent } from "react"

/* Picture */
import Picture from "./Picture"

/* Constants */
import { env, utils } from "../constants"

/* Fields */
const titles = {
    '': 'Menu',
    'history': 'History',
    'payout': 'Payout',
    'rules': 'Rules'
}

class GameModal extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            closeAnimation: false,
        }

        this._modal = null
    }

    componentDidUpdate = prevProps => {

        const { visible } = this.props
        const width = window.innerWidth

        if (width <= 600) {
            if (visible !== prevProps.visible) {
                if (visible) {
                    this.addListener()
                }
                else {
                    this.removeListener()
                }
            }
        }

    }

    addListener = () => {
        if (this._modalHeader) {
            this._modalHeader.addEventListener("touchstart", this.handleTouch, false)
            this._modalHeader.addEventListener("touchmove", this.handleTouch, false)
            this._modalHeader.addEventListener("touchend", this.handleTouchEnd, false)
        }
    }

    removeListener = () => {
        if (this._modalHeader) {
            this._modalHeader.removeEventListener("touchstart", this.handleTouch, false)
            this._modalHeader.removeEventListener("touchmove", this.handleTouch, false)
            this._modalHeader.removeEventListener("touchend", this.handleTouchEnd, false)
        }
    }

    handleTouch = event => {
        const y = event.changedTouches[0].clientY

        if (y > 320) this._modal.style.bottom = `${-y + 320}px`
    }

    handleTouchEnd = event => {

        const y = event.changedTouches[0].clientY
        const height = window.innerHeight

        if (y > height / 2) {
            this.closeModal()
        }
        else {
            this._modal.style.bottom = 0 + 'px'
        }
    }

    closeModal = () => {

        const { close } = this.props

        if (close) {
            this.setState({ closeAnimation: true })
            setTimeout(() => {
                close && close()
                this.setState({ closeAnimation: false })
            }, 400)
        }

    }

    render = () => {

        const { visible, selected, setSelected, children } = this.props
        const { closeAnimation } = this.state

        let style = ''

        if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
            style += 'safari'
        } else {
            style += ''
        }

        if (closeAnimation) {
            style += ' close-animation'
        }

        if (visible) {
            return (
                <div className="game-modal-back">
                    <div className="game-modal-backdrop" onClick={() => this.closeModal()} />
                    <div className={`game-modal ${style}`} ref={ref => this._modal = ref}>

                        <div className="modal-menu-body">

                            <div className="menu-header" ref={ref => this._modalHeader = ref}>

                                {selected !== '' && <div className="back-img" onClick={() => setSelected('')}>
                                    <Picture src={`${env.mediapoint}/images/menu/back.png`} alt="back" />
                                </div>}

                                <div className="menu-title">{utils.translate(titles[selected])}</div>

                                <div onClick={() => this.closeModal()} className="menu-close">
                                    <Picture src={`${env.mediapoint}/images/close.png`} alt="back" />
                                </div>

                            </div>

                            {children}

                        </div>
                    </div>
                </div>
            )
        }

        return null

    }

}

export default GameModal