import React, { Component } from "react"

/* Constants */
import { env, utils, closed } from '../constants'

/* Picture */
import { Picture, Card } from "../components"

/* Widget Dealer Cards */
class DealerCards extends Component {

    _result = () => {

        const { game } = this.props

        if (game) {

            if (game.level === 0) {
                return <div className="dealer-game-status red">{utils.translate('NO GAME')}</div>
            }

            const text = `${utils.translate(game.name)}${game.hasAceKing ? ` + ${utils.translate('ACE KING')}` : ""}`
            let size = "default"

            if (text.length > 20) {
                size = "smaller"
            }

            if (game.level > 0) {
                return (<div className={`dealer-game-status green ${size}`}>{text}</div>)
            }

        }
    }


    render = () => {

        const { cards, game, solution, removedCard, playerCards } = this.props

        let dealerCards = utils.get_unique_values(cards, "uuid")

        const sixthCard = playerCards.length === 6

        return (
            <div className="dealer-container" style={{ opacity: solution === "FOLD" ? 0.5 : 1 }}>

                <div className="dealer-container-header">
                    <div className="dealer-container-title">{utils.translate("DEALER'S HAND")}</div>
                    {this._result()}
                </div>

                <div className="dealer-cards">
                    {dealerCards.map((card, index) => {

                        const status = game || index === 4 ? true : false
                        const i = game && game.data ? game.data.findIndex(e => parseInt(e.id) === parseInt(card.id) && e.status === true) : -1
                        const removed = removedCard && removedCard.id === card.id

                        const key = index === 4 ? card.uuid : index

                        return (
                            <div className={`dealer-card ${i > -1 ? 'dealer-card-animated' : 'dealer-card-hidden'}`} key={`d-${key}`}>
                                <div className={`dealer-flip-card  ${status ? 'open' : 'hidden'}`}>
                                    <div className="dealer-flip-card-inner">
                                        <div className="dealer-flip-card-front">
                                            <img src={closed.image} alt="Closed" />
                                        </div>
                                        <div className={`dealer-flip-card-back ${removed ? "blured" : ""}`}>
                                            {status ? <Card data={card.name} /> : <img src={closed.image} alt="Closed" />}
                                            {removed ? <Picture src={`${env.mediapoint}/images/removed.png`} className="removed" alt="removed" /> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )

                    })}
                </div>

                {!sixthCard &&
                    <div className="dealer-spaces">
                        {[1, 2, 3, 4, 5].map(key =>
                            <div key={`${key}`} className="dealer-space">
                                <div className="dealer-space-circle">
                                    <div className="dealer-space-diamond"></div>
                                </div>
                                <div className="dealer-space-round" />
                            </div>
                        )}
                    </div>
                }


            </div>
        )
    }

}

export default DealerCards