import React, { PureComponent } from "react"

/* Constants */
import { env, utils, closed, sound } from '../constants'

/* Picture */
import { Card, Picture } from "../components"

/* Widget Player Cards */
class PlayerCards extends PureComponent {


    /* Draw Result */
    _result = () => {

        /* Fields */
        const { game, sixthGame } = this.props

        if (sixthGame && Array.isArray(sixthGame) && sixthGame.length > 0) {

            let result = ""

            sixthGame.forEach((sg, index) => {
                if (index <= 1) {
                    if (parseInt(sg.level) > 0) {

                        if (index === 1) {
                            result = result + " + "
                        }

                        result = result + utils.translate(sg.name)
                    }
                }
            })

            if (result === "") {
                return <div className="player-game-status red">{utils.translate('NO GAME')}</div>
            }

            let size = "default"
            if (result.length > 20) {
                size = "smaller"
            }

            return <div className={`player-game-status green ${size}`}>{result}</div>
        }

        if (game) {

            if (game.level === 0) {
                return <div className="player-game-status red">{utils.translate('NO GAME')}</div>
            }

            if (game.level > 0) {

                const text = `${utils.translate(game.name)}${game.hasAceKing ? ` + ${utils.translate("ACE KING")}` : ""}`
                let size = "default"
                if (text.length > 20) {
                    size = "smaller"
                }

                return <div className={`player-game-status green ${size}`}>{text}</div>
            }

        }

    }

    /* Card status */
    status = card => {

        const { game, sixthGame } = this.props

        let status = "player"

        if (game && game.data) {
            const index = game.data.findIndex(e => parseInt(e.id) === parseInt(card.id))
            if (index > -1) {
                if (game.data[index].status) {
                    status = "animated"
                }
            }
        }

        if (sixthGame && Array.isArray(sixthGame) && sixthGame.length > 0) {
            sixthGame.forEach(item => {
                const index = item.data.findIndex(e => parseInt(e.id) === parseInt(card.id))
                if (index > -1) {
                    if (item.data[index].status) {
                        status = "sixth"
                    }
                }
            })
        }

        return status
    }


    /* Card Open Status */
    openStatus = card => {

        const { exchanged } = this.props

        const index = exchanged.findIndex(e => parseInt(e) === parseInt(card.id))

        if (index > -1) {
            return "closed"
        }

        return "open"
    }


    /* Toggle close */
    toggle = card => {

        const { used, cards, exchanged, setExchanged, isExchange, volume } = this.props

        if (!used && cards.length >= 5 && !isExchange) {

            let list = exchanged

            const index = exchanged.findIndex(e => parseInt(e) === parseInt(card.id))

            if (index > -1) {
                list.splice(index, 1)
            }
            else {
                list.push(parseInt(card.id))
            }

            setExchanged(list)

            if (volume) {
                sound.play('card', 0.2)
            }
        }
    }


    /* Exchange Status */
    exchangeStatus = card => {

        const { exchanged, isExchange } = this.props

        const index = exchanged.findIndex(e => parseInt(e) === parseInt(card.id))

        if (index > -1 && isExchange) {
            return "remove"
        }

        return ""

    }

    render = () => {

        const { cards, solution, used, isExchange } = this.props

        const playerCards = utils.get_unique_values(cards, "uuid")

        const sixthCard = playerCards.length === 6 && !isExchange

        return (
            <div className="player-container" style={{ opacity: solution === "FOLD" ? 0.5 : 1 }}>

                <div className="player-container-head">
                    <div className="player-container-title">{utils.translate("YOUR HAND")}</div>
                    {this._result()}
                </div>

                <div className={`player-cards ${sixthCard ? "six-card" : ""}`}>
                    {playerCards.map(card =>
                        <div onClick={() => this.toggle(card)} className={`player-card ${this.exchangeStatus(card)}`} key={`p-${card.uuid}`} style={{ cursor: cards.length >= 5 && !used ? 'pointer' : 'default' }}>
                            <div className={`player-flip-card ${this.openStatus(card)}`}>
                                <div className="player-flip-card-inner">
                                    <div className="player-flip-card-front">
                                        <img src={closed.image} alt="Closed" />
                                    </div>
                                    <div className="player-flip-card-back">
                                        <div className={`${this.status(card)}`}>
                                            <Card data={card.name} player={true} />
                                            {card.isSixth && <div className="player-card-additional">+1</div>}
                                            {card.isExchange &&
                                                <div className="player-card-additional">
                                                    <Picture src={`${env.mediapoint}/images/arrows.png`} className="arrow" alt="Arrows" />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {
                    !sixthCard ?
                        <div className="player-spaces">
                            {[1, 2, 3, 4, 5].map(key =>
                                <div key={`${key}`} className={`player-space ${cards.length >= 5 && !used ? 'space-shadow' : ''}`}>
                                    <div className="player-space-circle">
                                        <div className="player-space-diamond"></div>
                                    </div>
                                    <div className="player-space-round" />
                                </div>
                            )}
                        </div>
                        : null
                }


            </div>
        )
    }

}

export default PlayerCards