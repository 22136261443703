import React, { PureComponent } from "react"

/* Constants */
import { utils } from "../constants"


/* Component Insurance */
class Insurance extends PureComponent {

    render = () => {

        const { onClick, isFourButton } = this.props

        return <div onClick={() => onClick()} className={`game-button insurance-button ${isFourButton}`}>{utils.translate("INSURANCE")}</div>
    }

}

export default Insurance