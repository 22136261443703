import React, { PureComponent } from "react"

class ModalAnimated extends PureComponent {

    render = () => {

        const { visible, children } = this.props

        return (
            <div className={`modal-animated ${visible ? 'visible' : ''}`}>
                {children}
            </div>
        )

    }

}

export default ModalAnimated