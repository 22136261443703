import React, { PureComponent } from "react"

/* Constants */
import { utils } from "../constants"


/* Widget Mobile Payout */
class MobilePayout extends PureComponent {

    constructor() {
        super()
        this.state = {
            select: 0
        }
    }

    render = () => {

        const { select } = this.state
        const { payout } = this.props

        return (
            <div className="mobile-payout">
                <div className="switch-selector">
                    <div className={`switch ${!select ? 'active' : ''}`} onClick={() => this.setState({ select: 0 })}>{utils.translate('Game')}</div>
                    <div className={`switch ${select ? 'active' : ''}`} onClick={() => this.setState({ select: 1 })}>{utils.translate('Bonus')}</div>
                </div>
                <div className={`payout-content ${select === 1 ? "payout-content-bonus" : "payout-content-table"}`}>
                    {payout}
                </div>
            </div>
        )
    }

}

export default MobilePayout