import React, { PureComponent } from "react"

/* Constants */
import { utils } from "../constants"

/* Component Sixth */
class Sixth extends PureComponent {

    render = () => {
        const { onClick } = this.props

        return <div onClick={() => onClick()} className="game-button sixth-button">{utils.translate("+1 CARD")}</div>
    }

}

export default Sixth