import React, { PureComponent } from 'react'

/* Toast notification */
import { ToastContainer } from 'react-toastify'


class Notification extends PureComponent {
    render = () => {

        return (
            <ToastContainer
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        )
    }

}

export default Notification