import en from './en'
import ru from './ru'
//import kk from './kk'

const languages = [
    { code: "en", name: "English" },
    { code: "ru", name: "Русский" },
    //{ code: "kk", name: "Қазақша" },
]

const data = { en, ru }

export { languages, data }