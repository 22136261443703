import React, { PureComponent } from "react"

/* Constants */
import { utils } from "../constants"

/* Widget Purchase */
class Purchase extends PureComponent {

    render = () => {

        const { purchase, isPurchase, setPurchase } = this.props

        return (
            <div className={`purchase-container ${isPurchase && !purchase ? 'visible' : 'closed'}`}>
                <div className="purchase-header">{utils.translate("BUY A GAME FROM A DEALER?")}</div>
                <div className="purchase-content">
                    <div className="purchase-button" onClick={(() => setPurchase("yes"))}>{utils.translate("YES")}</div>
                    <div className="purchase-button red" onClick={(() => setPurchase("no"))}>{utils.translate("NO")}</div>
                </div>
            </div>
        )
    }

}

export default Purchase