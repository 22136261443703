import React, { PureComponent } from "react"

/* Component Picture */
class Picture extends PureComponent {

    constructor() {
        super()

        this.state = {
            isLoaded: false
        }
    }

    render = () => {

        const { src, alt, className, onClick } = this.props
        const { isLoaded } = this.state

        return (
            <img
                src={src}
                alt={alt}
                onLoad={() => this.setState({ isLoaded: true })}
                onError={() => this.setState({ isLoaded: false })}
                style={{ opacity: isLoaded ? 1 : 0, transition: 'all 0.4s' }}
                className={className}
                onClick={onClick}
            />
        )
    }

}

export default Picture