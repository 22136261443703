import React, { PureComponent } from "react"

/* Constants */
import { utils } from "../constants"


/* Component Exchange */
class Exchange extends PureComponent {

    render = () => {

        const { onClick } = this.props

        return <div onClick={() => onClick()} className="game-button exchange-button">{utils.translate("EXCHANGE")}</div>
    }

}

export default Exchange