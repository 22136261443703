import React, { PureComponent } from "react"

/* Components */
import { Cash } from '../components'

/* Constants */
import { utils } from "../constants"


/* Widget Balance */
class Balance extends PureComponent {

    render = () => {

        const { balance, total, lastWin, currency } = this.props

        const tagText = (lastWin && lastWin > 0) ? utils.translate('LAST WIN:') : utils.translate('TOTAL:')
        const tagTotal = (lastWin && lastWin > 0) ? lastWin : total
        const tagColor = (lastWin && lastWin > 0) ? "green" : "blue"

        return (
            <div className="balance">

                <Cash mobile={false} />

                <div className="balance-box">
                    <div className="balance-text">{utils.translate('BALANCE:')}</div>
                    <div className="balance-value">{balance ? utils.getWithCurrency(balance, currency) : "0"}</div>
                </div>

                <div className="balance-box">
                    <div className="balance-text">{tagText}</div>
                    <div className={`balance-value ${tagColor}`}>{tagTotal ? utils.getWithCurrency(tagTotal, currency) : "0"}</div>
                </div>

            </div>
        )
    }

}

export default Balance