import React, { PureComponent } from "react"

/* Constants */
import { utils } from "../constants"

/* Component START */
class Start extends PureComponent {

    render = () => {

        const { ante, onClick } = this.props

        return <div onClick={() => onClick()} className="game-button start-button" style={{ opacity: ante > 0 ? 1 : 0.5 }}>{utils.translate("START")}</div>
    }

}

export default Start