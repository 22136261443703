import React, { PureComponent } from "react"

/* Components */
import { Chip } from '../components'

/* Constants */
import { env, utils, sound } from '../constants'

/* Picture */
import { Picture } from "../components"


/* Widget Chips */
class Chips extends PureComponent {

    constructor() {
        super()

        this.state = {
            list: []
        }
    }

    setChip = (value, index) => {
        const { ante, bonus, setStake, selected, setSelected, list, setList, balance, game, currency, volume } = this.props

        if (selected !== index) {
            setSelected(index)
            if (volume) {
                sound.play('selectchip', 0.2)
            }
        }
        else {

            if (((ante + parseInt(value)) * 4 + bonus) > balance) {
                utils.toggleError(utils.translate("You don't have enough funds"), "error", "nomoney")
            }
            else if (ante + parseInt(value) > game.max) {
                utils.toggleError(`${utils.translate("Maximum bid")} ${utils.getWithCurrency(game.max, currency)}`, "error", "maxbidante")
            }
            else {
                setStake({ ante: ante + parseInt(value), bonus: bonus })

                list.push({ value, type: "add", target: "ante" })
                setList(list)

                if (volume) {
                    sound.play('sound', 0.2)
                }
            }

        }

    }

    clear = () => {
        const { ante, setStake, setList, volume } = this.props
        if (ante) {
            setStake({ ante: 0, bonus: 0 })
            setList([])

            if (volume) {
                sound.play('chip2', 0.2)
            }
        }
    }

    cancel = () => {
        const { ante, bonus, setStake, list, setList, volume } = this.props
        let cancel = list

        const index = list.findLastIndex(e => e.type === "add")

        let stake = { ante, bonus }

        if (index > -1) {
            const last = cancel[index]
            if (last.target === 'ante') {
                if (stake.ante - last.value >= 0) {
                    stake.ante -= last.value
                    list[index].type = "remove"
                }
                if (last.double) {
                    if (stake.bonus) {
                        stake.bonus /= 2
                        const ind = list.findLastIndex(e => e.type === "add" && e.target === "bonus")
                        list[ind].type = "remove"
                    }
                }
            }
            if (last.target === 'bonus') {
                if (stake.bonus - last.value >= 0) {
                    stake.bonus -= last.value
                    const ind = list.findLastIndex(e => e.type === "add" && e.target === "bonus")
                    list[ind].type = "remove"
                }
            }
            setStake(stake)
            setList(cancel)
            if (volume) {
                sound.play('chip2', 0.2)
            }
        }
    }

    double = () => {
        const { ante, bonus, setStake, list, setList, balance, game, currency, volume } = this.props

        if (ante > 0) {


            if ((ante * 8 + bonus * 2) > balance) {
                utils.toggleError(utils.translate("You don't have enough funds"), "error", "nomoney")
                return
            }

            if (ante * 2 > game.max) {
                utils.toggleError(`${utils.translate("Maximum bid")} ${utils.getWithCurrency(game.max, currency)}`, "error", "maxbidante")
                return
            }

            if (bonus * 2 > game.maxBonus) {
                utils.toggleError(`${utils.translate("Maximum bid for bonus")} ${utils.getWithCurrency(game.maxBonus, currency)}`, "error", "maxbidbonus")
                return
            }

            setStake({ ante: ante * 2, bonus: bonus * 2 })

            if (bonus) {
                list.push({ value: ante, type: "add", target: "bonus", double: true })
            }

            if (ante) {
                list.push({ value: ante, type: "add", target: "ante", double: true })
            }

            setList(list)


            if (volume) {
                sound.play('sound', 0.2)
            }
        }
    }


    refresh = () => {

        const { ante, last, setStake, list, setList, balance, volume } = this.props

        if (ante === 0 && (last && last.ante > 0)) {

            if (last.ante * 4 + last.bonus > balance) {
                utils.toggleError(utils.translate("You don't have enough funds"), "error", "nomoney")
                return
            }

            setStake(last)

            if (last.ante) {
                list.push({ value: last.ante, type: "add", target: "ante" })
            }
            if (last.bonus) {
                list.push({ value: last.bonus, type: "add", target: "bonus" })
            }
            setList(list)

            if (volume) {
                sound.play('repeat', 0.2)
            }
        }
    }

    render = () => {

        const { selected, ante, last, chips } = this.props

        const btnStyle = ante ? { opacity: 1 } : { opacity: 0.5, cursor: 'default' }
        const refreshStyle = ante === 0 && (last && last.ante) > 0 ? { opacity: 1 } : { opacity: 0.5, cursor: 'default' }

        return (
            <div className="chips-container">

                <div onClick={() => this.clear()} className="chips-button" style={btnStyle}>
                    <Picture src={`${env.mediapoint}/images/delete.png`} alt="Delete" />
                    {utils.translate('Clear')}
                </div>

                <div onClick={() => this.cancel()} className="chips-button" style={btnStyle}>
                    <Picture src={`${env.mediapoint}/images/cancel.png`} alt="Cancel" />
                    {utils.translate('Cancel')}
                </div>

                <div className="chips-box">
                    {chips.map((chip, index) =>
                        <div onClick={() => this.setChip(chip.value, index)} key={`${index}`} className={`chip ${selected === index ? 'active' : 'disabled'}`}>
                            <Chip color={chip.color} value={chip.value} />
                        </div>
                    )}
                </div>

                <div onClick={() => this.double()} className="chips-button" style={btnStyle}>
                    <span>2x</span>
                    {utils.translate('Double')}
                </div>

                <div onClick={() => this.refresh()} className="chips-button" style={refreshStyle}>
                    <Picture src={`${env.mediapoint}/images/refresh.png`} alt="Refresh" />
                    {utils.translate('Repeat')}
                </div>

            </div>
        )
    }

}

export default Chips